import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import styled from "styled-components";


const Div = styled.div`
display: flex;
gap: 40px;
margin-left: auto;
margin-right: auto;
max-width: 800px;
justify-content: center;
/* justify-items: top; */
/* text-align: center; */
justify-self: center;
margin-top: 30px;
margin-bottom: 30px;


@media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
text-align: center;
align-items: center;

}


`

const Books = () => {
  return (
    <div>
      <Header />

      <Div style={{ marginTop: "30px" }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <b style={{fontSize: "20px"}}>New Books by Ali Afshari</b>

          <b style={{marginTop: "20px", color: "#77bdfb", fontSize: "18px", marginBottom: "20px"}}>Leica Design 101</b>
          <img style={{ borderRadius: "10px", width: "300px" }} title="" alt="Optomechanix" src="http://optomechanix.org/Journals/images/6.jpg" />
          <a style={{textAlign: "center", marginTop: "10px", marginBottom: "20px", color: "#ff991b"}} href="https://a.co/d/aQVF3L3">Available on Amazon.com</a>
          <b style={{textAlign: "center", marginBottom: "10px", marginTop: "20px"}}>Watch related Video on Youtube</b>
          <iframe width="300" height="215" src="https://www.youtube.com/embed/f1avKRicO6w" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
        </div>

        <div style={{ display: "flex", flexDirection: "column", verticalAlign: "top" }}>
          <b style={{marginTop: "50px", color: "#77bdfb", fontSize: "18px", marginBottom: "17px"}}>Restoring the SLR</b>
          <img style={{ borderRadius: "10px", width: "300px", height: "363px" }} title="" alt="Optomechanix" src="http://optomechanix.org/Journals/images/72.jpg" />
          <a style={{textAlign: "center", marginTop: "10px", color: "#ff991b"}} href="https://a.co/d/6NFNgIK">Available on Amazon.com</a>
        </div>
      </Div>

      <Footer />
    </div>
  );
};

export default Books;
