import React from "react";
import useTitle from "../hooks/useTitle";

import Header from "../layout/Header";
import Footer from "../layout/Footer";

const journals = [
  {
    title: "Optomechanix2",
    date: "Jun-Sep 2017",
    pic: "http://optomechanix.org/Journals/Optomechanix2.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix2.pdf",
  },
  {
    title: "Optomechanix3",
    date: "Oct-Dec 2017",
    pic: "http://optomechanix.org/Journals/Optomechanix3.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix3.pdf",
  },
  {
    title: "Optomechanix4",
    date: "Jan-Mar 2018",
    pic: "http://optomechanix.org/Journals/Optomechanix4.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix4.pdf",
  },
  {
    title: "Optomechanix5",
    date: "Apr-Jun 2018",
    pic: "http://optomechanix.org/Journals/Optomechanix5.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix5.pdf",
  },
  {
    title: "Optomechanix6",
    date: "Jul-Sep 2018",
    pic: "http://optomechanix.org/Journals/Optomechanix6.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix6.pdf",
  },
  {
    title: "Optomechanix7",
    date: "Oct-Dec 2018",
    pic: "http://optomechanix.org/Journals/Optomechanix7.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix7.pdf",
  },
  {
    title: "Optomechanix8",
    date: "Jan-Apr 2019",
    pic: "http://optomechanix.org/Journals/Optomechanix8.png",
    pdf: "http://optomechanix.org/Journals/Optomechanix8.pdf",
  },
  {
    title: "Optomechanix9",
    date: "Apr-Jun 2019",
    pic: "http://optomechanix.org/Journals/Optomechanix9.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix9.pdf",
  },
  {
    title: "Optomechanix10",
    date: "Jul-Sep 2019",
    pic: "http://optomechanix.org/Journals/Optomechanix10.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix10.pdf",
  },
  {
    title: "Optomechanix11",
    date: "Oct-Dec 2019",
    pic: "http://optomechanix.org/Journals/Optomechanix11.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix11.pdf",
  },
  {
    title: "Optomechanix12",
    date: "Jan-Mar 2020",
    pic: "http://optomechanix.org/Journals/Optomechanix12.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix12.pdf",
  },
  {
    title: "Optomechanix13",
    date: "Apr-Jun 2020",
    pic: "http://optomechanix.org/Journals/Optomechanix13.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix13.pdf",
  },
  {
    title: "Optomechanix14",
    date: "Jul-Sep 2020",
    pic: "http://optomechanix.org/Journals/Optomechanix14.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix14.pdf",
  },
  {
    title: "Optomechanix15",
    date: "Oct-Dec 2020",
    pic: "http://optomechanix.org/Journals/Optomechanix15.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix15.pdf",
  },
  {
    title: "Optomechanix16",
    date: "Jan-Mar 2021",
    pic: "http://optomechanix.org/Journals/Optomechanix16.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix16.pdf",
  },
  {
    title: "Optomechanix17",
    date: "Apr-Jun 2021",
    pic: "http://optomechanix.org/Journals/Optomechanix17.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix17.pdf",
  },
  {
    title: "Optomechanix18",
    date: "Jul-Sep 2021",
    pic: "http://optomechanix.org/Journals/Optomechanix18.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix18.pdf",
  },
  {
    title: "Optomechanix19",
    date: "Oct-Dec-2021",
    pic: "http://optomechanix.org/Journals/Optomechanix19.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix19.pdf",
  },
  {
    title: "Optomechanix20",
    date: "Jan-Mar 2022",
    pic: "http://optomechanix.org/Journals/Optomechanix20.png",
    pdf: "http://optomechanix.org/Journals/Optomechanix20.pdf",
  },
  {
    title: "Optomechanix21",
    date: "Feb 2022",
    pic: "http://optomechanix.org/Journals/Optomechanix21.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix21.pdf",
  },
  {
    title: "Optomechanix22",
    date: "Apr-Jun 2022",
    pic: "http://optomechanix.org/Journals/Optomechanix22.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix22.pdf",
  },
  {
    title: "Optomechanix23",
    date: "Jul-Sep 2022",
    pic: "http://optomechanix.org/Journals/Optomechanix23.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix23.pdf",
  },
  {
    title: "Optomechanix24",
    date: "Oct-Dec 2022",
    pic: "http://optomechanix.org/Journals/Optomechanix24.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix24.pdf",
  },
  {
    title: "Optomechanix25",
    date: "Jan-Mar 2023",
    pic: "http://optomechanix.org/Journals/Optomechanix25.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix25.pdf",
  },
  {
    title: "Optomechanix26",
    date: "Apr-Jun 2023",
    pic: "http://optomechanix.org/Journals/Optomechanix26.jpg",
    pdf: "http://optomechanix.org/Journals/Optomechanix26.pdf",
  },
  {
    title: "Optomechanix27",
    date: "Jul-Sep 2023",
    pic: "http://optomechanix.org/Journals/Optomechanix27.JPG",
    pdf: "http://optomechanix.org/Journals/Optomechanix27.pdf",
  },
  {
    title: "Optomechanix28",
    date: "Oct-Dec 2023",
    pic: "http://optomechanix.org/Journals/Optomechanix28.JPG",
    pdf: "http://optomechanix.org/Journals/Optomechanix28.pdf",
  },
  {
    title: "Optomechanix29",
    date: "Jan-Mar 2024",
    pic: "http://optomechanix.org/Journals/Optomechanix29.JPG",
    pdf: "http://optomechanix.org/Journals/Optomechanix29.pdf",
  },
];

export default function Home() {
  useTitle("Optomechanix");

  return (
    <>
      <Header />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginTop: "30px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyItems: "center",
          justifyContent: "center",
          justifySelf: "center",
          textAlign: "center",
          gap: "5px",
        }}
      >
        <b style={{ fontSize: "20px", color: "#77bdfb" }}>
          {" "}
          Latest Issue of Optomechanix Journal
        </b>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          flexWrap: "wrap",
          maxWidth: "700px",
          gap: "10px",
          marginTop: "10px",
          marginLeft: "auto",
          width: "95%",
          marginRight: "auto",
          justifyItems: "center",
          justifyContent: "center",
          justifySelf: "center",
        }}
      >
        <a
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            justifySelf: "center",
            width: "130px",
          }}
          href={journals[journals?.length - 1]?.pdf}
          target="_blank"
        >
          <img
            style={{ borderRadius: "4px" }}
            width={300}
            height={400}
            title={journals[journals?.length - 1]?.title}
            alt="Optomechanix"
            src={journals[journals?.length - 1]?.pic}
          />
        </a>
        <a
          style={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            marginLeft: "auto",
            marginRight: "auto",
            justifySelf: "center",
            width: "130px",
          }}
          href={journals[journals?.length - 1]?.pdf}
          target="_blank"
        >
          <p
            style={{
              marginTop: "10px",
              marginBottom: "20px",
              fontSize: "24px",
              whiteSpace: "nowrap",
            }}
          >
            {journals[journals?.length - 1]?.date}
          </p>
        </a>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginBottom: "20px",
          marginTop: "10px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyItems: "center",
          justifyContent: "center",
          justifySelf: "center",
          textAlign: "center",
          gap: "5px",
        }}
      >
        <b style={{ fontSize: "20px" }}>⎯⎯⎯⎯ Past Issues ⎯⎯⎯⎯</b>
        <p style={{ fontSize: "15px", color: "#e4565d" }}>
          Click on Covers to Download
        </p>
      </div>

      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          maxWidth: "800px",
          gap: "18px",
          marginTop: "50px",
          marginLeft: "auto",
          marginRight: "auto",
          justifyItems: "center",
          justifyContent: "center",
          justifySelf: "center",
        }}
      >
        {journals?.slice(0, -1)?.map(({ title, pic, pdf, date }, index) => (
          <div key={index}>
            <a
              style={{
                display: "flex",
                alignContent: "center",
                alignItems: "center",
                justifyItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                marginLeft: "auto",
                marginRight: "auto",
                justifySelf: "center",
                width: "130px",
              }}
              href={pdf}
              target="_blank"
            >
              <img
                style={{ borderRadius: "4px" }}
                width={130}
                height={180}
                title={title}
                alt="Optomechanix"
                src={pic}
              />
              <p style={{ marginTop: "10px", marginBottom: "20px" }}>{date}</p>
            </a>
          </div>
        ))}
      </div>

      <Footer />
    </>
  );
}
