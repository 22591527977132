import React, { useState } from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import styled from "styled-components";

const slides = [{ url: "http://optomechanix.org/Journals/images/1.jpg" }, { url: "http://optomechanix.org/Journals/images/2.jpg" }, { url: "http://optomechanix.org/Journals/images/3.jpg" }, { url: "http://optomechanix.org/Journals/images/4.jpg" }, { url: "http://optomechanix.org/Journals/images/5.jpg" }];

const PicDiv = styled.div`
  width: 800px;
  object-fit: contain;
  /* max-height: 500px; */

  @media (max-width: 768px) {
    width: 90%;
  }
`;

const ContactUs = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  setTimeout(() => {
    const isLastSlide = currentIndex === slides.length - 1;
    const newIndex = isLastSlide ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  }, 10000);

  const goToSlide = (index) => {
    setCurrentIndex(index);
  };
  return (
    <div>
      <Header />

      <div style={{ display: "flex", flexWrap: "wrap", gap: "18px", width: "95%", marginTop: "50px", marginLeft: "auto", marginRight: "auto", justifyItems: "center", justifyContent: "center", justifySelf: "center" }}>
        <PicDiv style={{ justifyItems: "center", justifyContent: "center", justifySelf: "center" }}>
          <img
            style={{
              maxWidth: "100%",
              // objectFit: 'none',
              height: "auto",
            }}
            alt=""
            src={slides[currentIndex]?.url}
          />
          <div style={{ display: "flex" }}>
            {slides.map((item, index) => (
              <div
                key={index}
                style={{
                  width: "300px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  justifyItems: "center",
                  justifyContent: "space-around",

                  cursor: "pointer",
                  marginLeft: "auto",
                  marginRight: "auto",
                  marginTop: "-90px",
                  color: "white",
                  fontSize: "4rem",
                  transitionDuration: "300ms",
                  opacity: index === currentIndex ? 1 : 0.5,
                }}
                onClick={() => goToSlide(index)}>
                _
              </div>
            ))}
          </div>
        </PicDiv>
      </div>

      <div style={{ display: "flex", flexDirection: "column", marginTop: "30px", marginBottom: "50px", marginLeft: "auto", marginRight: "auto", justifyItems: "center", justifyContent: "center", justifySelf: "center", textAlign: "center", gap: "5px" }}>
        <p>
          <b style={{ color: "#77bdfb" }}>Web:</b> www.optomechanix.org
        </p>
        <p>
          <b style={{ color: "#77bdfb" }}>Email:</b> info@optomechanix.org
        </p>
        <b style={{ color: "#77bdfb" }}>Opto-Mechanical Institute of Design</b>
        <p>Fremont, CA USA</p>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
