import React, { useEffect } from "react";
import { Route, Routes, Navigate } from "react-router-dom";


import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import Books from "./pages/Books";
import Articles from "./pages/Articles";
import ContactUs from "./pages/ContactUs";



const App = () => {


  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/books" element={<Books />} />
      <Route path="/articles" element={<Articles />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/NotFound" element={<NotFound />} />
      <Route path="/*" element={<Navigate to="/NotFound" />} />
    </Routes>
  );
};

export default App;
