import React from "react";
import Header from "../layout/Header";
import Footer from "../layout/Footer";
import styled from "styled-components";


const Div = styled.div`
display: flex;
gap: 20px;
margin-left: auto;
margin-right: auto;
max-width: 800px;
justify-content: center;
justify-items: center;
/* text-align: center; */
justify-self: center;
align-items: center;
margin-top: 30px;
margin-bottom: 30px;

@media (max-width: 768px) {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
text-align: center;
}


`



const Articles = () => {
  return (
    <div>
      <Header />


      <Div>
      <div style={{display: "flex", flexDirection: "column"}}>
      <img style={{borderRadius: "10px", width: "300px"}}  title="" alt="Optomechanix" src="http://optomechanix.org/Journals/Articles/demoo.png" />


</div>
          <div style={{display: "flex", flexDirection: "column"}}>
               <b style={{fontSize: "20px", marginBottom: "20px"}}>Articles</b>
               <a style={{fontSize: "16px", marginBottom: "5px", fontWeight: "400"}} href="http://optomechanix.org/Journals/Articles/erectoropt.pdf">• 85 years History of Optical Erector Set</a>
               <a style={{fontSize: "16px", marginBottom: "5px", fontWeight: "400"}} href="http://optomechanix.org/Journals/Articles/iraniancinemaopt.pdf">• Optical Testing and Repair for Iranian Cinema</a>
               <a style={{fontSize: "16px", marginBottom: "5px", fontWeight: "400"}} href="http://optomechanix.org/Journals/Articles/photomarketopt.pdf">• Photonics Market Forecast for Optical Engineers</a>
               <a style={{fontSize: "16px", marginBottom: "5px", fontWeight: "400"}} href="http://optomechanix.org/Journals/Articles/chinaopt.pdf">• China's fast paced growth in Optomechanics</a>
               <a style={{fontSize: "16px", marginBottom: "5px", fontWeight: "400"}} href="http://optomechanix.org/Journals/Articles/enneagram2.pdf">• Enneagram I</a>
               <a style={{fontSize: "16px", marginBottom: "5px", fontWeight: "400"}} href="http://optomechanix.org/Journals/Articles/enneagrampart3.pdf.pdf">• Enneagram II</a>


          </div>

  


      </Div>


      <Footer />
    </div>
  );
};

export default Articles;
