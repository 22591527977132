import React from "react";
import { NavLink } from "react-router-dom";

import styled from "styled-components";
import logoWhite from "../assets/img/logoWhite.png";

import Hambergur from "../components/Hamburger";

const Header = () => {
  const HEADER = styled.header`
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: linear-gradient(180deg, #161b22 0%, #161b22 50%);
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
    height: 60px;

    a {
      text-decoration: none;
      font-size: 1.2rem;
      font-weight: 600;
      color: #fff;
      margin: 0 0 0 2rem;
    }
  `;

  return (
    <>
      <HEADER>
        <div style={{marginTop: "5px"}}>
          <NavLink to="/">
            <img width={100} height={45} title="Optomechanix" alt="" src={logoWhite} />
          </NavLink>
        </div>

        <Hambergur />
      </HEADER>
    </>
  );
};

export default Header;
